@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #3d3d3d;
}

::-webkit-scrollbar
{
	width: 10px;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #333030;
}

body {
  background: #000000;
  background-position:center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("https://arkane-worldwide.com/wp-content/uploads/2023/11/gradient-bg-02-2.jpg");
  color: #fff;
  font-family: "DM Sans", sans-serif;
  overflow-x: hidden;

  img.header {
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
  }

  img.footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: -1;
  }

  header {
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #505050;
    background: rgba(30, 30, 30, 0.5);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    flex-shrink: 0;

    .goback-website {
      flex: 1;

      a {
        color: #fff;
        margin-left: 50px;
        transition: all 0.2s ease;

        &:hover {
          color: #87a2d1;
          text-decoration: underline;
        }
      }
    }

    .logo {
      flex: 1;
      img {
        width: 80px;
        aspect-ratio: 1;
      }
    }

    .empty {
      flex: 1;
    }
  }

  footer {
    flex-shrink: 0;
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #505050;
    background: rgba(30, 30, 30, 0.5);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);

    .credits {
      display: flex;
      flex: 1;
      align-items: center;
      gap: 20px;

      img {
        margin-left: 50px;
        width: 50px;
        aspect-ratio: 1;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
          color: #fff;
          transition: color 0.2s ease;

          &:hover {
            cursor: pointer;
            color: #87a2d1;
          }
        }

        span.author {
          font-size: 12px;
        }
      }
    }

    .socials {
      flex: 1;
      display: flex;
      justify-content: flex-end;

      .container {
        display: flex;
        gap: 20px;
        margin-right: 50px;

        a {
          color: #fff;

          i {
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #87a2d1;
            padding: 8px;
            border-radius: 50%;
            transition: background 0.2s ease;
  
            &:hover {
              background: #6e85ac;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  main {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 200px);
    justify-content: flex-start;
    align-items: center;
    padding: 30px 150px;
    gap: 100px;

    .top-part {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 30px;

      h2.loading-text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        i {
          color: #ffffff;
        }
      }

      .title {
        text-align: center;
      }

      .search-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background: rgba(30, 30, 30, 0.9);
        border: 1px solid #353535;
        border-radius: 10px;
        width: fit-content;
        margin: auto;
        padding: 0 15px;
        user-select: none;
        transition: border 0.3s ease;
        text-overflow: ellipsis;

        i {
          font-size: 25px;
        }

        input {
          outline: none;
          background: none;
          border: none;
          height: 45px;
          width: 30vw;
          color: #fff;
          font-size: 20px;
          font-family: "DM Sans", sans-serif;
          font-weight: bold;
          text-overflow: ellipsis;
        }

        &:hover {
          border: 1px solid #cecece;
        }
      }

      .stats {
        display: flex;
        flex-direction: column;
        gap: 15px;
        background: rgba(30, 30, 30, 0.9);
        border: 1px solid #353535;
        border-radius: 10px;
        width: 20vw;
        min-width: 384px;
        padding: 16px;
        margin: auto;

        .streams-count,
        .viewers-count {
          display: flex;
          justify-content: space-between;
        }

        * {
          .left {
            img {
              user-select: none;
            }
          }
          
          .left,
          .right {
            display: flex;
            gap: 5px;
            font-size: 1.2rem;
          }
        }
      }
    }

    .streams-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      flex-direction: column;
      border-radius: 10px;
      padding: 30px;
      border: 1px solid #505050;
      background: rgba(30, 30, 30, 0.5);

      .stream-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 300px;

        .thumbnail-container {
          position: relative;
          width: 300px;
          height: 168.61px;

          img.stream-thumbnail {
            width: 300px;
            overflow: hidden;
            border-radius: 8px;
            transition: all .25s, visibility .25s ease-in;

            &:hover {
              transform: scale(1.03);
              opacity: 0.80;
              cursor: pointer;
            }
          }

          .viewer-count-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            position: absolute;
            bottom: 5px;
            right: 5px;
            background-color: #272727a4;
            padding: 4px 6px;
            border-radius: 4px;
            color: #fff;

            img.icon-viewer-count {
              width: 15px;
            }
          }
        }

        .stream-details {
          display: flex;
          flex-direction: row;
          gap: 10px;

          img.stream-profile {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }

          .stream-info {
            display: flex;
            flex-direction: column;
            gap: 0;
            text-overflow: ellipsis;
            height: 70px;
            overflow: hidden;
            white-space: nowrap; 

            span.stream-username {
              display: flex;
              font-size: 20px;
              font-weight: 700;
              color: #fff;
              transition: 0.2s ease color;

              &:hover {
                cursor: pointer;
                color: #bf94ff;
              }
            }
            span.stream-title {
              color: #fff;
              height: 44px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap; 
              transition: 0.2s ease color;

              &:hover {
                cursor: pointer;
                color: #87a2d1;
              }
            }
          }
        }
      }

      &:has(h3) {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1396px) {
    main {
      .streams-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        flex-direction: column;
        border-radius: 10px;
        padding: 30px;
        border: 1px solid #505050;
        background: rgba(30, 30, 30, 0.5);
      }
    }
  }

  @media screen and (max-width: 1040px) {
    main {
      .streams-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
        flex-direction: column;
        border-radius: 10px;
        padding: 30px;
        border: 1px solid #505050;
        background: rgba(30, 30, 30, 0.5);
      }
    }
  }

  @media screen and (max-width: 734px) {
    main {
      .top-part {
        .search-bar {
          min-width: 384px;

          input {
            min-width: 322px;
          }
        }
      }

      .streams-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 20px;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        padding: 30px;
        border: 1px solid #505050;
        background: rgba(30, 30, 30, 0.5);
      }
    }
  }

  @media screen and (max-width: 632px) {
    main {
      min-height: calc(100vh - 290px);
    }

    header {
      height: 140px;

      .goback-website,
      .logo-header,
      .empty {
        flex: none;
      }

      .goback-website {
        a {
          padding-left: 0;
        }
      }

      flex-direction: column;
    }

    footer {
      flex-direction: column;
      gap: 10px;
      height: 150px;

      .credits {
        flex: none;
        img {
          margin-left: 0;
        }
      }

      .socials {
        flex: none;
        .container {
          margin-right: 0;
        }
      }
    }
  }
}
